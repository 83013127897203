// Generated by Framer (d19f530)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Pz7myLcwv", "fyp38cvLr"];

const variantClassNames = {fyp38cvLr: "framer-v-ir1x9q", Pz7myLcwv: "framer-v-1k1l5ey"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "Pz7myLcwv", "Variant 2": "fyp38cvLr"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Pz7myLcwv", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Pz7myLcwv", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fU7A9", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1k1l5ey", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Pz7myLcwv"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({fyp38cvLr: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-b3lp1t"} layoutDependency={layoutDependency} layoutId={"iv8kD51sZ"} style={{backgroundColor: "rgb(187, 221, 255)"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fU7A9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fU7A9 .framer-mk7vi7 { display: block; }", ".framer-fU7A9 .framer-1k1l5ey { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-fU7A9 .framer-b3lp1t { flex: none; height: 200px; position: relative; width: 200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fU7A9 .framer-1k1l5ey { gap: 0px; } .framer-fU7A9 .framer-1k1l5ey > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-fU7A9 .framer-1k1l5ey > :first-child { margin-top: 0px; } .framer-fU7A9 .framer-1k1l5ey > :last-child { margin-bottom: 0px; } }", ".framer-fU7A9.framer-v-ir1x9q .framer-b3lp1t { height: 100%; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fyp38cvLr":{"layout":["fixed","fixed"]}}}
 */
const Framertxj9Lqaf_: React.ComponentType<Props> = withCSS(Component, css, "framer-fU7A9") as typeof Component;
export default Framertxj9Lqaf_;

Framertxj9Lqaf_.displayName = "Scale";

Framertxj9Lqaf_.defaultProps = {height: 800, width: 1200};

addPropertyControls(Framertxj9Lqaf_, {variant: {options: ["Pz7myLcwv", "fyp38cvLr"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framertxj9Lqaf_, [])